// Body
$body-bg: #f8fafc;
// Typography
$font-family-sans-serif: "Nunito",
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
// Colors
$blue: #3490dc;
$deepblue: #2F4269;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$lightorange: #fbbd82;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$eu:#7D3629;
$ee: #E8DF4A;
$mgie: #D32E53;
$gestionsite: #91A7D7;
.blue {
    color: $blue;
}

.indigo {
    color: $indigo;
}

.purple {
    color: $purple;
}

.pink {
    color: $pink;
}

.red {
    color: $red;
}

.orange {
    color: $orange;
}

.yellow {
    color: $yellow;
}

.green {
    color: $green;
}

.teal {
    color: $teal;
}

.cyan {
    color: $cyan;
}

.sidebar-deepblue {
    background-color: #2F4269;
}
