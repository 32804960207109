// // Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// // Variables
// @import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
$fa-font-path: "../webfonts";
// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import '~admin-lte/build/scss/AdminLTE.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/v4-shims.scss';
// @import '~bootstrap/scss/bootstrap';
@import '~vue-select/dist/vue-select.css';
@import '_custom.scss';
