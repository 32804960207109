        /* CUSTOM STYLES */

        // Pour masquer le contenu avant fin chargement page
        // Ne marche que pour la page en elle-même, pas pour le contenu Ajax ...
        // Aujourd'hui (07/04/2020) = pas utilisé
        [v-cloak] {
            display: none;
        }

        pre{
            font-family: $font-family-sans-serif !important;
            padding: inherit !important;
        }

        // Drop shadow sur icones font-awesome
        .iconshadow{
           filter: drop-shadow(0 0 0.2rem #888888);
        }

        // Overlay au dessus de Pace pour empêcher la saisie avant la fin
        // Abandonné pour l'instant, on verra plus tard.
        // Le code est commenté dans app.blade.php
        #page_overlay {
            position: fixed;
            position: fixed;
            z-index: 2000;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
        }

        div[name="Datatable"] {
            width: 98%;
        }

        .nav-sidebar>.nav-item .nav-icon {
            font-size: 1.1rem;
        }

        .sidebar .nav-link p {
            font-size: 1.2em;
        }

        .sidebar .espacesite {
            border: 1px solid #ffffff;
            margin-bottom: 4px;
        }

        .badge.navbar-badge {
            width: 15px;
            position: absolute;
            left: 18px;
            top: 5px;
            padding: 2px 4px;
            font-size: 0.6rem;
        }

        .badge-selecteur {
            position: absolute;
            top: 3px;
            right: 1px;
            font-size: 1em;
        }

        blockquote {
            border-left: 0.7rem solid #f5f5f5;
            font-size: 0.9rem!important;
        }

        .heighauto {
            height: auto;
        }

        .mh-400 {
            min-height: 400px;
        }

        .notificationBell {
            font-size: 1.3em;
        }

        // .navbar-expand .navbar-nav .nav-link{
        //     padding-right: 1rem;
        //     padding-left: 1rem;
        // }
        .brand-link {
            -moz-box-shadow: 0px 3px 3px -3px #ffffff;
            -webkit-box-shadow: 0px 3px 3px -3px #ffffff;
            -o-box-shadow: 0px 3px 3px -3px #ffffff;
            box-shadow: 0px 3px 3px -3px #ffffff;
            filter: "progid: DXImageTransform.Microsoft.Shadow(color=#ffffff, Direction=180, Strength=3)";
            // border-bottom:2px solid white;
            line-height: 1.3 !important;
        }

        .navbar-laravel {
            background-color: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
        }

        .nav-pills .nav-link {
            color: white;
            font-size: 0.9em;
        }

        .border-bottom-red {
            -moz-border-image: url(/img/bottom-red-line.png) 20 stretch repeat;
            -webkit-border-image: url(/img/bottom-red-line.png) 20 stretch repeat;
            -ms-border-image: url(/img/bottom-red-line.png) 20 stretch repeat;
            border-image: url(/img/bottom-red-line.png) 20 stretch repeat;
            border-width: 0 0 9px;
            // border-bottom: 1px solid #843824;
            // border: 9px solid transparent;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-bottom: 9px;
        }

        .border-bottom-white {
            border-bottom: 1px solid white;
        }
        /* HEADER topbar */

        .main-header .navbar-nav {
            .nav-item {
                margin-right: 0.5rem;
            }
            .nav-item:last-child {
                margin-right: 0;
            }
            .deconnect.d-sm-inline-block,
            .support.d-sm-inline-block {
                font-size: 1.3em;
                margin-top: 3px!important;
                a {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .logo-header {
            position: absolute;
            left: calc(50% - 110px);
            top: 8px;
            width: 230px;
        }

        .sitechange {
            .btn:first-child {
                padding-right: 0;
            }
            .v-select .dropdown-toggle {
                border-right: 0;
                margin-right: 0;
                padding-right: 0;
            }
            .btn:last-child {
                padding-left: 0!important;
                padding-right: 0!important;
                margin-left: -2px;
                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    padding: 0.28rem 0.5rem!important;
                }
            }
            .dropdown.open {
                min-width: 180px;
                .dropdown-menu {
                    min-width: 300px;
                    margin-left: -84px;
                    border-top: 1px solid #ccc;
                }
                .dropdown-toggle .form-control {
                    min-width: 180px;
                }
            }
        }

        header.main-header {
            border-bottom: 0;
            -webkit-box-shadow: none;
            -o-box-shadow: none;
            box-shadow: none;
            filter: "progid: DXImageTransform.Microsoft.Shadow(color=#ffffff, Direction=0, Strength=0)";
        }

        nav.main-header.navbar {
            background: url(/img/ligne-header-site.png);
            padding-bottom: 5em;
            background-position-x: 50%;
            background-position-y: bottom;
            background-repeat: repeat-x;
            border-bottom: 0;
            -webkit-box-shadow: none;
            -o-box-shadow: none;
            box-shadow: none;
            filter: "progid: DXImageTransform.Microsoft.Shadow(color=#ffffff, Direction=0, Strength=0)";
        }

        .d-sm-inline-block {
            margin-top: 10px !important;
        }

        .color-eu {
            color: $eu;
        }

        .bg-eu {
            background-color: $eu;
            color: #fff !important;
        }

        .bg-ee {
            background-color: $ee;
        }

        .bg-mgie {
            background-color: $mgie;
            color: white;
        }

        .bg-gestionsite {
            background-color: $gestionsite;
            color: white;
        }

        .header-espace {
            clear: both;
            position: absolute;
            right: 50px;
            top: 10px;
            font-size: 1.3em;
        }

        .fil-ariane {
            clear: both;
            position: absolute;
            left: 25px;
            top: 69px;
        }
        /* CARD */

        .card-eu.card-outline {
            border-top: 3px solid #F2610E;
        }

        .card-eu .card-body {
            max-height: 180px;
            overflow-y: auto;
        }

        .card-eu.heightauto .card-body {
            max-height: 100%;
        }

        .card-eu.height10em .card-body {
            max-height: 10em;
        }
        .card-eu.height20em .card-body {
            max-height: 20em;
        }
        .card-eu .card-title i {
            color: $eu;
        }

        .moduleSelector {
            position: absolute;
            bottom: 12px;
            left: 0px;
        }

        .tooltip.show {
            z-index: 10000;
        }

        .dropdown-menu-right {
            // background-color: red;
            width: 390px;
        }

        .notificationBox {
            min-width: 370px;
            min-height: 260px;
            background-color: #197aab;
            background-image: url('/img/persona1.png');
            background-repeat: no-repeat;
            background-position: bottom 0px left 15px;
            background: rgba(42, 191, 255, 1);
            background: url('/img/persona1.png') bottom 0px left 15px no-repeat, -moz-linear-gradient(45deg, rgba(42, 191, 255, 1) 0%, rgba(34, 157, 214, 1) 36%, rgba(25, 122, 171, 1) 74%, rgba(25, 122, 171, 1) 100%);
            background: url('/img/persona1.png') bottom 0px left 15px no-repeat, -webkit-gradient(left bottom, right top, color-stop(0%, rgba(42, 191, 255, 1)), color-stop(36%, rgba(34, 157, 214, 1)), color-stop(74%, rgba(25, 122, 171, 1)), color-stop(100%, rgba(25, 122, 171, 1)));
            background: url('/img/persona1.png') bottom 0px left 15px no-repeat, -webkit-linear-gradient(45deg, rgba(42, 191, 255, 1) 0%, rgba(34, 157, 214, 1) 36%, rgba(25, 122, 171, 1) 74%, rgba(25, 122, 171, 1) 100%);
            background: url('/img/persona1.png') bottom 0px left 15px no-repeat, -o-linear-gradient(45deg, rgba(42, 191, 255, 1) 0%, rgba(34, 157, 214, 1) 36%, rgba(25, 122, 171, 1) 74%, rgba(25, 122, 171, 1) 100%);
            background: url('/img/persona1.png') bottom 0px left 15px no-repeat, -ms-linear-gradient(45deg, rgba(42, 191, 255, 1) 0%, rgba(34, 157, 214, 1) 36%, rgba(25, 122, 171, 1) 74%, rgba(25, 122, 171, 1) 100%);
            background: url('/img/persona1.png') bottom 0px left 15px no-repeat, linear-gradient(45deg, rgba(42, 191, 255, 1) 0%, rgba(34, 157, 214, 1) 36%, rgba(25, 122, 171, 1) 74%, rgba(25, 122, 171, 1) 100%);
            filter: "progid: DXImageTransform.Microsoft.gradient( startColorstr='#2abfff', endColorstr='#197aab', GradientType=1)";
        }

        .notificationMsg {
            padding: 30px 0px 20px 150px;
            color: white;
            font-size: 1.2em;
        }

        .notificationIcon {
            width: 2.4em;
            height: 2.4em;
            position: absolute;
            left: -15px;
            top: -10px;
        }

        .notificationFleche {
            width: 42px;
            height: 30px;
            position: absolute;
            right: 20px;
            top: -31px;
        }

        .notificationButton {
            position: absolute;
            bottom: 5px;
            right: 5px;
        }

        .notificationBox.dropdown-menu {
            margin-top: 2.3rem;
        }

        @media print {
            .nav.nav-tabs li:not(.active) {
                display: none;
            }
            .invoice {
                padding: 10px 20px;
            }
            .invoice {
                background-position: center center;
                background-repeat: no-repeat;
            }
            .table-responsive table {
                background-color: transparent !important;
            }
        }

        @media (max-width: 575.98px) {
            .header-espace {
                font-size: 1.1em;
                text-align: center;
                margin: 0 auto 0;
                width: 65%
            }
            .fil-ariane {
                font-size: 0.8em;
            }
            .logo-header {
                top: 44px;
            }
            .logo-header img {
                width: 200px;
            }
        }

        // Fin media max-width 575.98px
        .tel {
            text-decoration: underline!important;
        }

        @page {
            margin: 0;
        }
        /* test sidebar */

        .sidebar {
            // add scroll
            height: 80%!important;
            overflow: scroll-y;
        }

        .main-sidebar {
            // padding-bottom: 3em;
        }

        @media (min-width: 992px) {
            body:not(.sidebar-mini-md) .main-header {
                margin-left: 125px;
            }
            .sidebar-mini.sidebar-collapse .content-wrapper,
            .sidebar-mini.sidebar-collapse .main-footer,
            .sidebar-mini.sidebar-collapse .main-header {
                margin-left: 2.3rem !important;
            }
            .sidebar-mini.sidebar-collapse .content-wrapper,
            .sidebar-mini.sidebar-collapse .main-footer {
                margin-left: 5rem !important;
            }
        }

        // .sidebar {
        //     width: 250px;
        //     float: left;
        // }
        // @media (min-width: 768px) {
        //     .sidebar-collapse .content-wrapper,
        //     .sidebar-collapse .main-footer,
        //     .sidebar-collapse .main-header {
        //         margin-left: 0;
        //     }
        // }
        // .sidebar-collapse .main-sidebar,
        // .sidebar-collapse .main-sidebar:before {
        //     margin-left: -250px;
        // }
        .layout-fixed .brand-link {
            width: 100%;
        }

        .brand-link {
            -webkit-box-shadow: 0px 2px 2px -3px #ffffff;
            -o-box-shadow: 0px 2px 2px -3px #ffffff;
            box-shadow: 0px 2px 2px -3px #ffffff;
            filter: "progid: DXImageTransform.Microsoft.Shadow(color=#ffffff, Direction=180, Strength=3)";
            // margin-bottom: 2em;
            line-height: 1.3 !important;
            text-align: center;
            img {
                max-height: 34px;
            }
            .logo-mini {
                display: none;
            }
        }

        .user-sidetop {
            -webkit-box-shadow: 0px 2px 2px -3px #ffffff;
            -o-box-shadow: 0px 2px 2px -3px #ffffff;
            box-shadow: 0px 2px 2px -3px #ffffff;
            filter: "progid: DXImageTransform.Microsoft.Shadow(color=#ffffff, Direction=180, Strength=3)";
            width: 100%;
            min-height: 75px;
            padding: 0.5rem;
            line-height: 1.2em;
            .image,
            .info {
                float: left;
            }
            .image {
                padding: 0;
                margin-left: 0.7em;
                img {
                    width: 50px;
                    height: 50px;
                    border: 3px solid #ffffff;
                    border-radius: 12px;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
                }
            }
            .info {
                color: #fff;
                width: 56%;
            }
            .info:before {
                display: block;
                width: 1px;
                height: 50px;
                background: #fff;
                content: " ";
                position: absolute;
                left: -0.7em;
            }
        }

        .sidebar-mini.sidebar-collapse {
            .brand-link {
                .logo-lg {
                    display: none;
                }
                .logo-mini {
                    display: block;
                }
            }
            .item-link p {
                display: none;
            }
            .user-sidetop {
                .info {
                    display: none;
                }
                .image {
                    margin-left: 0;
                }
            }
        }

        .nav-pills .nav-link:not(.active):hover {
            color: #fff;
            background-color: #2F4269;
            border-radius: 0;
        }

        .nav-sidebar>.nav-item>.router-link-exact-active {
            color: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
        }

        .nav-sidebar>.nav-item.menu-open>.nav-link,
        .nav-sidebar>.nav-item.menu-open>ul>.nav-item.menu-open>.nav-link,
        .nav-sidebar>.nav-item:hover>.nav-link,
        .nav-sidebar .nav-link:hover {
            background-color: rgba(255, 255, 255, .1);
        }

        .nav-sidebar>.nav-item.menu-open>ul>.nav-item.menu-open>.nav-link i.right {
            color: $orange;
        }

        .nav-sidebar>.nav-item.menu-open>.nav-treeview {
            background-color: rgba(255, 255, 255, .15);
        }

        .nav-sidebar .fa-circle {
            margin-left: .3em;
        }

        .nav-sidebar .nav-link {
            padding: 0.5rem;
        }

        .sidebar-mini.sidebar-collapse .nav-link {
            padding: 0.5rem 1rem;
        }

        .sidebar-mini.sidebar-collapse .brand-text,
        .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
        .sidebar-mini.sidebar-collapse .sidebar .user-panel>.info {
            margin-left: -10px;
            opacity: 0;
            visibility: hidden;
        }
        /* header */

        // body:not(.sidebar-mini-md) .content-wrapper,
        // body:not(.sidebar-mini-md) .main-footer,
        // body:not(.sidebar-mini-md) .main-header {
        //     -webkit-transition: margin-left 0.3s ease-in-out;
        //     transition: margin-left 0.3s ease-in-out;
        //     margin-left: 250px;
        //     position: absolute;
        // }
        .col-xs-12 {
            @extend .col-12;
        }

        .col-xs-6 {
            @extend .col-6;
        }

        .col-xs-3 {
            @extend .col-3;
        }

        div[name="HeaderSettings"] {
            display: none;
        }
        /* BUTTONS  */

        .info {
            color: $primary;
            position: absolute;
            right: 1em;
            top: 5em;
            z-index: 1035;
            cursor: pointer;
        }
        /* buttons: listing */

        .table .btn {
            width: 3em;
        }

        .btn.sep,
        .btn.sep:hover {
            border-left: 1px solid lightgrey;
        }

        .table .btn.fa-eye {
            // border-right: 2px solid #5a6268;
        }

        .table .btn.fa-eye {
            border: 1px solid #5a6268;
        }

        a.btn.btn-default.btn-sm {
            padding: 0.5rem;
        }
        /* CARD */

        .dashboard .card,
        .listing .card,
        .detail .card {
            margin-top: 1em;
        }

        // .card-body .btn-group,
        .card-header .btn-group,
        .card-footer .btn-group,
        .content-header .row .btn-group,
        .content-footer .row .btn-group {
            float: right!important;
            // margin-bottom: 2rem;
        }

        .content-footer {
            padding: 15px 1rem;
        }

        .btn-group.actions {
            margin-bottom: 0;
            // a:nth-child(1) {
            //     display: none;
            // }
            // a:nth-child(2) {
            //     border-top-left-radius: 0.2rem;
            //     border-bottom-left-radius: 0.2rem;
            // }
            // i.fa-eye:before {
            //     display: none;
            // }
        }

        .permissions.listing .btn-group.actions button:last-child {
            display: none;
        }

        .card .detail {
            position: absolute;
            top: 0.5rem;
            right: 1rem;
        }

        .card .header-label {
            position: absolute;
            top: 0.5rem;
            left: 30%;
        }

        .card-outline-tabs.card-primary.card-outline {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
        }
        /* listing */

        .listing {
            .searchbar {
                margin: 0 0 1em;
            }
            .card-header {
                border-bottom: none!important;
            }
            .card-body {
                padding-top: 0;
            }
            .table-striped {
                tbody tr:nth-of-type(n+1) {
                    background-color: rgba(0, 0, 0, 0.05);
                }
                tbody tr:nth-of-type(2n+1) {
                    background-color: #ffffff;
                }
                thead {
                    background: #5a6268;
                    th {
                        color: #ffffff;
                        .fa-sort {
                            color: #ffffff!important;
                        }
                    }
                }
                margin-bottom:1em!important;
            }
            /* detail */
            .detail .card {
                width: 100%;
            }
            /* edit */
            .edit {
                .card-primary.card-outline {
                    // border-top: none;
                    border-top: 1px solid #ededed;
                }
                .box-footer {
                    text-align: right;
                }
            }
        }
        /* docs */

        .docs {
            text-align: center;
            a {
                font-size: 0;
            }
            a:before {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                font-size: 16px;
                display: inline-block;
                content: '\f56d';
            }
        }

        /* ul no-style */
        ul.no-bullets {
            list-style-type: none; /* Remove bullets */
            padding: 0; /* Remove padding */
            margin: 0; /* Remove margins */
          }

        /* breadcrumbs */

        ul.breadcrumbs-container {
            display: inline;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        ul.breadcrumbs-container li {
            display: inline;
        }

        ul.breadcrumbs-container li+li:before {
            padding: 8px;
            color: black;
            content: ">\00a0";
        }

        .tobpar-vselect .vs__actions button.clear {
            display: none;
        }

        .v-select {
            min-width: 240px;
        }

        .card .table {
            td {
                padding: 0.25rem;
                vertical-align: middle;
                border-top: 1px solid #dee2e6;
            }
            th {
                border-top: 0;
            }
        }

        .card .card-body {
            // padding-top: 0px
        }

        // form generic
        input[name="code_postal"] {
            max-width: 120px;
        }

        input[name*="telephone_"],
        input[name="fax"] {
            max-width: 250px;
        }
        /* button + select btn-group */

        // .v-select.drop-up.vs--open .vs__dropdown-toggle {
        //     border-radius: 0 0 4px 4px;
        //     border-top-color: transparent;
        //     border-bottom-color: rgba(60, 60, 60, 0.26);
        // }
        // .vs__dropdown-toggle {
        //     border-right: none;
        //     border-top-right-radius: 0;
        //     border-bottom-right-radius: 0;
        // }
        [data-popper-placement='top'] {
            border-radius: 4px 4px 0 0;
            border-top-style: solid;
            border-bottom-style: none;
            box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
        }

        .btn-detail.creer {
            display: inline-flex;
            // height: 30px;
        }

        .btn-select {
            .btn:first-child {
                padding-right: 0;
            }
            .v-select .dropdown-toggle {
                border-right: 0;
                margin-right: 0;
                padding-right: 0;
                border-top-left-radius: 4px!important;
                border-bottom-left-radius: 4px!important;
            }
            .btn:last-child {
                margin-left: -5px;
                border-top-left-radius: 0!important;
                border-bottom-left-radius: 0!important;
                padding: 0.25rem 0.5rem;
                padding-right: 0.5rem;
                font-size: 0.7875rem;
                line-height: 1.5;
                height: 30px;
                z-index: 10;
            }
            .dropdown.open {
                min-width: 180px;
                .dropdown-menu {
                    min-width: 300px;
                    margin-left: -84px;
                    border-top: 1px solid #ccc;
                }
                .dropdown-toggle .form-control {
                    min-width: 180px;
                }
            }
        }
        /* Formatage données */

        .nom {
            text-transform: uppercase;
        }
        /* EPI */

        .epi .card-body div {
            float: left;
            margin-left: 5px;
            width: 80px;
            height: 115px;
            text-align: center;
            line-height: 1em;
        }
        /* Enter and leave animations can use different */
        /* durations and timing functions.              */

        .slide-fade-enter-active {
            transition: all .5s ease;
        }

        .slide-fade-leave-active {
            transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        }

        .slide-fade-enter,
        .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */

        {
            transform: translateX(25px);
            opacity: 0;
        }
        /* EE Identité */

        .eeidentite .vs__selected-options {
            width: 500px;
        }

        .eepersonnels .identite table {
            margin-top: 0px;
        }

        // label.required{
        //     color: red;
        // }
        // input:optional {
        //     border-color: #008000;
        //   }
        // input:required {
        // border-color: #800000;
        // }
        // espacement <span> simulation colonne
        .tableLikeSpan {
            li span:last-child {
                font-weight: bold;
                float: right;
                padding-left: 0.33em;
                background: white;
            }
            li span:first-child {
                padding-right: 0.33em;
                background: white
            }
            li:before {
                float: left;
                width: 0;
                white-space: nowrap;
                color: #eee;
                content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
            }
            padding: 0;
            overflow-x: hidden;
            list-style: none
        }

        .isDisabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
          a[aria-disabled="true"] {
            opacity: 0.5;
            cursor: not-allowed;
            display: inline-block;  /* For IE11/ MS Edge bug */
            pointer-events: none;
            text-decoration: none;
          }

          input::placeholder, textarea::placeholder {
            color: #BFBFBF !important;
            font-style: italic;
            font-size: 0.8em;
          }

        // Non utilisé
          .rawdisplay{
            white-space: pre-line;
            vertical-align:top !important;
          }
          .valign-top{
              vertical-align:top !important;
          }

          section .badge {white-space: break-spaces!important;}
